@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Open Sans", Arial, Verdana, Tahoma, sans-serif;
  background-color: white;
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  height: 100%;
}

#app-container {
  box-sizing: border-box;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}

.footer {
  flex-grow: 0;
  flex-shrink: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom_black {
  background-color: #2a2a2a;
}

/*Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar .active p {
  border-left: 0.25rem solid black;
  color: white;
  padding: 10px;
  background-color: black;
}
